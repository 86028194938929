import React from 'react'
import ComingSoon from '../../components/coming-soon'
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner10@2x.jpg'),
    heading: "Grow your business through partnership marketing",
    subheading: "Affiliate schemes built directly into Billsby make it easy to track referrals, attribute sales and calculate and pay out affiliate commission - making affiliate links and management as easy as it was to integrate checkout. An additional transaction fee applies on top of the Billsby Pro fee for this service."
  }

  return(
    <Layout>
      <SEO 
        title="Affiliate Schemes | Billsby Pro | Take your subscription business to the next level" 
        description="Affiliate Schemes and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/affiliate-scheme"
      />
      
      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;